const PlayIcon = () => {
    return (
        <svg
            width="30"
            height="33"
            viewBox="0 0 30 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M26.5 13.9019C28.5 15.0566 28.5 17.9434 26.5 19.0981L6.25 30.7894C4.25 31.9441 1.75 30.5007 1.75 28.1913L1.75 4.80866C1.75 2.49925 4.25 1.05588 6.25 2.21058L26.5 13.9019Z"
                stroke="white"
                strokeWidth="3"
            />
        </svg>
    );
};

export default PlayIcon;
