const LightSvg = () => {
    return (
        <svg
            width="1605"
            height="2090"
            viewBox="0 0 1605 2090"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_f_973_2348)">
                <path
                    d="M1176.38 813.502C1427.15 1225.78 1333.42 1767.89 1240.66 1797.09C1025.39 1928.03 982.859 1534.39 732.087 1122.11C481.315 709.83 117.265 435.024 332.535 304.085C547.805 173.146 925.607 401.22 1176.38 813.502Z"
                    fill="url(#paint0_linear_973_2348)"
                    fillOpacity="0.3"
                />
            </g>
            <defs>
                <filter
                    id="filter0_f_973_2348"
                    x="0.467102"
                    y="0.464172"
                    width="1603.78"
                    height="2088.89"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur
                        stdDeviation="133.333"
                        result="effect1_foregroundBlur_973_2348"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_973_2348"
                    x1="687.975"
                    y1="1099.08"
                    x2="1292.97"
                    y2="1951.98"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#972EFF" stopOpacity="0.6" />
                    <stop offset="1" stopColor="#5B89FF" stopOpacity="0.4" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default LightSvg;
