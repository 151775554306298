import { Graph, LargeScreenGraph } from "../../icon/Graph";
import GreenUpperArrow from "../../icon/GreenUpperArrow";
const SmallChartCard = ({ item }) => {
    return (
        <div
            className={`rounded-[12px] bg-white p-3 laptop:rounded-[16px] laptop:p-5`}
        >
            <div className="flex flex-col gap-2 laptop:gap-5 2xl:flex-row 2xl:items-center">
                <span className="hidden 2xl:block">
                    <Graph />
                </span>
                <span className="2xl:hidden">
                    <LargeScreenGraph />
                </span>
                <span></span>
                <div className="">
                    <p className="flex items-center gap-x-2 font-Inter text-[24px] font-medium text-[black]">
                        {item.bold_text}
                        <span className="">
                            <GreenUpperArrow />
                        </span>
                    </p>
                    <p className="pt-[3px] font-Inter text-[14px] leading-5 text-[black] xl:text-sm xl:leading-6 2xl:text-[16px] 2xl:leading-7">
                        {item.comment}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default SmallChartCard;
