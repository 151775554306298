import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

const ClientPortal = ({ children, selector, show }) => {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = document.getElementById(selector);
    }, [selector]);
    return show && ref.current ? createPortal(children, ref.current) : null;
};
export default ClientPortal;
