import Image from "next/image";
import React from "react";
const CustomImage = ({ src, height, width, layout, className }) => {
    return (
        <>
            <Image
                src={src}
                height={height}
                width={width}
                layout={layout}
                className={className}
                alt="customization image"
            />
        </>
    );
};

export default CustomImage;
