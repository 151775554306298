import Image from "next/image";

const BigChartData = ({ item }) => {
    return (
        <div
            className={`rounded-[12px] bg-[#D6D9F2] p-3 laptop:rounded-[16px] laptop:p-5`}
        >
            <p className="text-[32px] text-[black] tab:text-[42px]">
                {item.bold_text}
            </p>
            <p className="font-Inter text-[13px] font-medium leading-[22px] text-[#575E67]">
                {item.comment}
            </p>
            {/* <img
                src="https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/graph-new.svg"
                alt=""
                className="mt-5"
            /> */}
            <Image
                src={
                    "https://fundednext.fra1.cdn.digitaloceanspaces.com/lander-v2/payout.svg"
                }
                alt=""
                className="pointer-events-none mt-5"
                layout="responsive"
                width={100}
                height={100}
            />
        </div>
    );
};

export default BigChartData;
