const NumberCard = ({ item }) => {
    return (
        <div
            className={`rounded-[12px] bg-[rgba(255,255,255,0.16)] p-3 laptop:rounded-[16px] laptop:p-5`}
        >
            <p className="inline-block rounded bg-[#7D82FF] px-2 py-[6px] font-Inter text-[12px] font-bold uppercase text-white">
                {item.info}
            </p>
            <h2 className=" text-[40px] font-semibold text-[rgba(255,255,255,0.80)] tab:text-[60px] tab:font-light laptop:text-[80px]">
                {item.bold_text}
            </h2>
            <p className="font-Inter text-[18px] font-medium leading-[22px] text-[rgba(255,255,255,0.60)] laptop:text-[20px]">
                {item.small_text}
            </p>
            <p className="mt-2 font-Inter  text-[14px] leading-5  text-[rgba(255,255,255,0.60)] xl:text-sm xl:leading-6  2xl:text-[16px] 2xl:leading-7">
                {item.comment}
            </p>
        </div>
    );
};

export default NumberCard;
