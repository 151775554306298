import { useEffect, useState } from "react";

const AnimatedCapsule = ({ text, bgColor = "#000000" }) => {
    const [active, setActive] = useState(0);

    useEffect(() => {
        let activeIndex = active;
        const interval = setInterval(() => {
            activeIndex++;
            activeIndex === 4 ? (activeIndex = 0) : activeIndex;
            setActive(activeIndex);
            console.log(activeIndex);
        }, 2000);

        return () => clearInterval(interval);
    }, []);

    const colors = [
        "linear-gradient(90deg, #593dff 0%, #13dbff 100%)",
        "linear-gradient(90deg, #35dbff 0%, #ff00d6 100%)",
        "linear-gradient(90deg, #02eac0 0%, #a615ff 100%)",
        "linear-gradient(90deg, #0019ff 0%, #ff8fd2 100%)"
    ];
    const textColors = [
        "-webkit-linear-gradient(0deg, #86a1ff 0%, #a7f2ff 100%)",
        "-webkit-linear-gradient(0deg, #A3ECFC 0%, #FEC4FF 100%)",
        "-webkit-linear-gradient(90deg, #E1F7D7 0%, #B6A4FF 100%)",
        "-webkit-linear-gradient(90deg, #8A94EC 0%, #E6A0DC 100%)"
    ];
    const bgColors = [
        `radial-gradient(53.91% 122.66% at 48.2% 155.54%, #3968A9 0%, ${bgColor} 100%)`,
        `radial-gradient(53.91% 122.66% at 48.2% 155.54%, #399CA9 0%, ${bgColor} 100%)`,
        `radial-gradient(53.91% 122.66% at 48.2% 155.54%, #4ABB7E 0%, ${bgColor} 100%)`,
        `radial-gradient(53.91% 122.66% at 48.2% 155.54%, #AF46A7 0%, ${bgColor} 100%)`
    ];

    return (
        <div className="relative z-[0] mt-10 inline-block overflow-hidden rounded-full bg-white p-[1px]">
            <div className="opacity-1 relative h-full w-full rounded-full px-[16px] py-[4px] text-[14px]">
                {bgColors.map((color, idx) => (
                    <div
                        className="absolute left-0 top-0 h-full w-full rounded-full"
                        key={"bgGradient" + idx}
                        style={{
                            backgroundImage: color,
                            opacity: idx === active ? 1 : 0
                        }}
                    ></div>
                ))}

                {textColors.map((color, idx) => (
                    <span
                        className="capsule-text font-Inter"
                        key={"textGradient" + idx}
                        style={{
                            backgroundImage: color,
                            opacity: idx === active ? 1 : 0,
                            transition: "opacity 0.3s"
                        }}
                    >
                        {text}
                    </span>
                ))}

                <span className="font-Inter opacity-0">{text}</span>
            </div>
            <div className="capsule-grad aspect-square w-full">
                {colors.map((color, idx) => (
                    <div
                        className="absolute left-0 top-0 h-full w-full"
                        key={"grad" + idx}
                        style={{
                            backgroundImage: color,
                            opacity: idx === active ? 1 : 0,
                            transition: "opacity 5s"
                        }}
                    ></div>
                ))}
                <div className="capsule-white-grad absolute left-[50%] top-0 h-1/2 translate-x-[-50%]"></div>
            </div>
        </div>
    );
};

export default AnimatedCapsule;
