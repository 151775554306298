import React from "react";
import { CloseIcon } from "../../../SVGIcons/SVGIcons";
import ClientPortal from "./ClientPortal";
// import ClientPortal from "../ModalV2/ClientPortal";

const ModalUpdated = ({ visible, children, handleClose }) => {
    return (
        <>
            <ClientPortal selector="updated-modal" show={visible}>
                <div
                    className="fixed left-0 top-0 z-[9999] flex h-full w-full items-center justify-center bg-black bg-opacity-25 backdrop-blur-md"
                >
                    <button
                        className="absolute right-4 top-4 h-9 w-9 rounded-full bg-black"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </button>
                    <div className="mx-3 w-[450px] sm:mx-0 sm:w-[550px] md:w-[650px] lg:w-[700px] 2xl:w-[750px]">
                        <div className="relative">{children}</div>
                    </div>
                </div>
            </ClientPortal>
        </>
    );
};

export default ModalUpdated;