const GradientStar = () => {
    return (
        <svg
            width="85"
            height="85"
            viewBox="0 0 85 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.6382 39.9936C18.9434 39.0549 19.4399 36.4958 21.3628 36.259L33.6415 34.7471C34.5015 34.6412 35.1955 33.9936 35.3605 33.143L37.7169 20.998C38.0859 19.096 40.6732 18.7775 41.4926 20.5331L46.7249 31.7437C47.0913 32.5288 47.9217 32.9887 48.7816 32.8828L61.0604 31.3709C62.9833 31.1341 64.0858 33.4963 62.6693 34.8181L53.6243 43.2586C52.9908 43.8497 52.81 44.7815 53.1764 45.5667L58.4087 56.7772C59.2281 58.5329 57.3222 60.3114 55.6274 59.3726L44.805 53.3786C44.047 52.9588 43.1049 53.0748 42.4715 53.6659L33.4265 62.1063C32.0099 63.4282 29.7295 62.1651 30.0986 60.2632L32.455 48.1182C32.62 47.2676 32.2186 46.4075 31.4606 45.9877L20.6382 39.9936Z"
                stroke="url(#paint0_linear_244_1948)"
                strokeWidth="2"
            />
            <path
                d="M29.5839 26.0515L26.4578 15.9161"
                stroke="url(#paint1_linear_244_1948)"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M23.4905 28.4297L17.375 24.0879"
                stroke="url(#paint2_linear_244_1948)"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M24.7818 25.1551L22.915 22.6027"
                stroke="url(#paint3_linear_244_1948)"
                strokeWidth="2"
                strokeLinecap="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_244_1948"
                    x1="15.2536"
                    y1="37.0113"
                    x2="69.2467"
                    y2="47.4872"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CA86FF" />
                    <stop offset="1" stopColor="#5920F7" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_244_1948"
                    x1="24.7055"
                    y1="19.2315"
                    x2="31.3362"
                    y2="22.7362"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CA86FF" />
                    <stop offset="1" stopColor="#5920F7" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_244_1948"
                    x1="17.6645"
                    y1="23.6802"
                    x2="23.7799"
                    y2="28.022"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CA86FF" />
                    <stop offset="1" stopColor="#5920F7" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_244_1948"
                    x1="22.6255"
                    y1="23.0103"
                    x2="25.0714"
                    y2="24.7474"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CA86FF" />
                    <stop offset="1" stopColor="#5920F7" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default GradientStar;
