const GradientQuote = () => {
    return (
        <svg
            width="53"
            height="48"
            viewBox="0 0 53 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M45.0504 39.7237L38.5511 40.9855C34.5194 41.7682 30.6037 39.1308 29.8224 35.1064L27.6184 23.7532C25.6218 13.4686 32.3798 3.4741 42.683 1.47387C44.029 1.21256 45.3317 2.08997 45.5926 3.43355C45.8534 4.77713 44.9736 6.07824 43.6276 6.33955C36.8438 7.65654 32.1392 13.6628 32.2359 20.3322L40.9572 18.6391C44.9889 17.8564 48.9046 20.4938 49.6858 24.5182L50.9453 31.0057C51.7266 35.0301 49.0822 38.941 45.0504 39.7237ZM17.6302 11.3866C18.9762 11.1253 19.856 9.82415 19.5952 8.48057C19.3343 7.137 18.0316 6.25958 16.6856 6.52089C6.38237 8.52112 -0.37563 18.5156 1.62098 28.8002L3.82505 40.1535C4.60633 44.1779 8.52201 46.8152 12.5537 46.0325L19.0531 44.7707C23.0848 43.988 25.7292 40.0772 24.9479 36.0528L23.6885 29.5652C22.9072 25.5408 18.9915 22.9034 14.9598 23.6861L6.23848 25.3793C6.14183 18.7099 10.8464 12.7036 17.6302 11.3866Z"
                stroke="url(#paint0_linear_244_1967)"
                strokeWidth="2"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_244_1967"
                    x1="21.5601"
                    y1="5.57457"
                    x2="28.8021"
                    y2="42.8781"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#56E7FF" />
                    <stop offset="1" stopColor="#0168E0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default GradientQuote;
