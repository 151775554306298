import Image from "next/image";
import PlayIcon from "../../../SVGIcons/PlayIcon";

const CelebrityCard = ({ celebrity, activeModalCallback, index }) => {
    return (
        <div
            className={`flex shrink-0 flex-col gap-6 mobile:flex-row mobile:gap-10 ${index === 2 && "pr-[20px]"} pad:pr-0`}
        >
            <div className="relative">
                <div className="h-[370px] w-[250px] xl:h-[400px] 2xl:w-[270px] ">
                    <Image
                        src={celebrity.image}
                        alt={celebrity.name + " image"}
                        className="rounded-[16px]"
                        layout="fill"
                    />
                </div>
                <button
                    aria-label="celebrity video play icon"
                    onClick={() => {
                        activeModalCallback(celebrity);
                    }}
                    className="group absolute left-[50%] top-[50%] flex h-[75px] w-[75px] translate-x-[-50%] translate-y-[-50%] cursor-pointer items-center justify-center rounded-full bg-black/20 backdrop-blur-[8px]"
                >
                    <span className="translate-x-1 scale-75 group-hover:scale-90">
                        <PlayIcon />
                    </span>
                </button>
                <div className="absolute left-[-20px] top-[-20px]">
                    {celebrity.icon}
                </div>
            </div>
            <div className="flex max-w-[220px] flex-col justify-between">
                <h2 className="text-[18px] font-medium leading-8 text-white mobile:text-[24px] mobile:font-bold mobile:leading-10">
                    "{celebrity.quote}
                    <i>"</i>
                </h2>
                <div className="mt-5 flex items-center mobile:mt-0 mobile:block">
                    <p className="text-[14px] font-bold text-white mobile:text-[16px]">
                        {celebrity.name}
                    </p>
                    <span className="ml-1 mr-3 text-white mobile:hidden">
                        ,
                    </span>
                    <small className="text-[14px] text-white/60">
                        {celebrity.designation}
                    </small>
                </div>
            </div>

            {/* <NewVideoModal
                open={open}
                onClose={() => setOpen(false)}
                src={modalVideo}
            /> */}
        </div>
    );
};

export default CelebrityCard;
