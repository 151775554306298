import Image from "next/image";

const BasicCard = ({ item }) => {
    return (
        <div
            className={` rounded-[12px] bg-white p-3 laptop:rounded-[16px] laptop:p-5`}
            style={{
                backgroundColor: item.bg ? item.bg : "#FFFFFF29"
            }}
        >
            <div className="flex items-center gap-x-[10px] pb-[10px]">
                {/* <img
                    src={item.img_url}
                    alt=""
                    className="h-[34px] w-[34px] rounded-full"
                /> */}
                <Image
                    src={item.img_url}
                    alt=""
                    className="h-[34px] w-[34px] rounded-full"
                    height={34}
                    width={34}
                />
                <p
                    className={` text-base font-[600] xl:text-lg`}
                    style={{
                        color: item.title_color ? item.title_color : "#ffffff"
                    }}
                >
                    {item.name}
                </p>
            </div>
            <p
                className={`font-Inter text-[13px] leading-5 lg:text-[14px] xl:text-sm xl:leading-6 2xl:text-[16px] 2xl:leading-7`}
                style={{
                    color: item.text_color ? item.text_color : "#FFFFFFB2"
                }}
            >
                {item.comment}
            </p>
        </div>
    );
};

export default BasicCard;
